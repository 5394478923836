$button-font-family: $font-family-base;
$button-font-weight: $font-weight-semibold;
$button-font-size: $font-size-s;
$button-height: 45px;
$button-radius: $border-radius-l;
$button-backoffice-radius: $border-radius-xl;

a,
button {
  @include focus();
}

@mixin button-basis() {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  min-height: $button-height;
  padding: $space-s;
  text-align: center;
  font-family: $button-font-family;
  font-weight: $button-font-weight;
  font-size: $button-font-size;
  text-transform: uppercase;
  border-radius: $button-radius;
  border: 1px solid;
  min-width: 120px;
  max-width: 100%;

  @include focus();

  &:hover,
  &:focus {
    cursor: pointer;
    outline: 0;
  }

  &[disabled] {
    opacity: 0.3;
    cursor: not-allowed;
  }
}

@mixin button-variant(
  $color,
  $background,
  $border,
  $border-radius,
  $color-hover: $color,
  $background-hover: darken($background, 10%),
  $border-hover: darken($border, 10%)
) {
  color: $color;
  background-color: $background;
  border-color: $border;

  &:hover,
  &:focus {
    color: $color-hover;
    background-color: $background-hover;
    border-color: $border-hover;
  }

  &[disabled] {
    opacity: 0.7;
    pointer-events: none;
    cursor: not-allowed;
  }
}

.c-btn {
  @include button-basis();

  &--default {
    @include button-variant($black, $white, $black, none);
  }

  // Modifiers (business)
  &--primary {
    @include button-variant($white, $primary-color, $primary-color, none);
  }

  &--green {
    @include button-variant($primary-color, $green60, $green60, none);
  }

  &--secondary {
    @include button-variant($white, $secondary-color, $secondary-color, none);
  }

  &--white {
    @include button-variant($primary-color, $white, $white, none);
  }

  &--white-bordered {
    @include button-variant($primary-color, $white, $primary-color, none);
  }

  &--danger {
    @include button-variant($white, $danger-color, $danger-color, none);
  }

  &--success {
    @include button-variant($white, $success-color, $success-color, none);
  }

  &--transparent {
    @include button-variant($black, transparent, transparent, none);
  }

  // Modifiers (size)
  &--s {
    min-height: 26px;
    padding: 0;
    font-size: $font-size-xxs;
  }
  &--m {
    min-height: 60px;
    padding: 0;
    font-size: $font-size-m;
  }

  &--download {
    border: 0;
    font-weight: normal;
    font-size: $font-size-xs;
    text-decoration: underline;
    display: inline-flex;
    min-height: 33px;
    transition: $transition-default;
    text-transform: inherit;
  }

  &--back-office {
    border-radius: $button-backoffice-radius;
    text-transform: none;
  }

  &.is-disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
}
