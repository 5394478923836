@import "styles/variables.scss";

.payment-page {
    .payment-table {
        border: solid 1px $primary-color;
        border-radius: 10px;
    }
    
    thead {
        th {
            background-color: $yellow !important;
            color: $white !important;
        }
    }
    
    tbody {
        tr {
            color: $black;
            &:not(:first-child){
                td {
                    border-top: 1px solid $grey70 !important;
                }
            }
        }
    }   
}
