@import 'styles/variables.scss';

.question {
    &:nth-child(odd) {
        background-color: $grey30;
        transition: background-color $transition-default;
    }
}

.btn-back-to-autodiag {
    text-decoration: underline;
}