.c-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 15px;
    padding-bottom: 10px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    background-color: $primary-color;
    color: $white;
    z-index: 10;

    height: $header-height;


    @media (min-width: $breakpoint-main) {
        position: fixed;
    }

    &__logo {
        height: 60px;
        width: auto;
    }

    &__menu {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        height: 100%;

        @media (max-width: $breakpoint-main - 1) {
            position: absolute;
            top: 0;
            left: 0;
            padding: ($header-height + 35) 0 10vh;
            height: 100vh;
            width: 100vw;
            flex-direction: column;
            justify-content: flex-start;
            background-color: $primary-color;
            transform: translateX(-100%);
            transition: transform $transition-default;
            z-index: -1;
            .is-open & {
                transform: translateX(0);
            }
        }
    }

    &__nav {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        list-style: none;
        height: 100%;

        @media (max-width: $breakpoint-main - 1) {
            flex-direction: column;
            justify-content: space-between;
            width: 100%;
            height: 100%;
            max-height: 280px;
            padding-bottom: $space-xl;
        }

        li {
            @media (max-width: $breakpoint-main - 1) {
                display: flex;
                width: 100%;
                height: 100%;
                justify-content: center;
                align-items: center;
            }

            a,
            button {
                text-transform: uppercase;
                position: relative;
                @include focus();
                &:hover {
                    color: $link-hover-color;
                    transition: color $transition-default;
                }

                &[disabled] {
                    opacity: 0.7;
                    pointer-events: none;
                }

                // &.c-btn {
                //     &:after { content: none; }
                // }
            }
        }
    }

    &__toggle {
        display: block;
        z-index: 1;

        width: 30px + $space-m * 2;
        height: 24px;
        display: inline-block;
        position: relative;

        @media (min-width: $breakpoint-main) {
            display: none;
        }

        &:focus {
            outline: 0;
        }

        // burger
        span {
            display: block;
            position: absolute;
            top: 50%;
            margin-top: -2px;
            background-color: $white;
            width: 25px;
            height: 3px;
            border-radius: 2px;
            transition: transform 0.075s cubic-bezier(0.55,0.055,0.675,0.19);

            &:before,
            &:after {
                content: '';
                display: block;
                position: absolute;
                background-color: $white;
                width: 25px;
                height: 3px;
                border-radius: 2px;
            }
            &:before {
                top: -8px;
                transition: top 0.075s 0.12s ease, opacity 0.075s ease;
            }
            &:after {
                bottom: -8px;
                transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55,0.055,0.675,0.19);
            }
        }

        &.is-open {
            span {
                transform: rotate(45deg);
                &:before {
                    top: 0;
                    opacity: 0;
                }
                &:after {
                    bottom: 0;
                    transform: rotate(-90deg);
                }
            }
        }
    }

    // Modifiers
    &--client {
        position: fixed;
        flex-direction: row;
        justify-content: space-between;
        border: 0;
    }
}