@import "styles/variables.scss";


.c-charter {
    min-height: 1000px;
    margin-bottom: 170px;

    .react-pdf__Page__canvas {
        margin: 0 auto;
        border: none;
        padding: 20px;
    }
}

.signature-panel-form {
    position: absolute;
    bottom: 160px;
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto; 
    width: 800px;
    
    .signature-panel {
        position: relative;
        background: $grey30;
        border-radius: 10px;
        padding: 10px;

        .infos-signature {
            width: 100%;
        }
    }
}