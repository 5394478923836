.c-card {
    background-color: $white;
    border-radius: $border-radius-l;
    box-shadow: 0px 10px 10px #1E0C5C2C;
}

.c-catalog-card {
    border: 3px solid $primary-color;
    display: block;
    color: $primary-color;
}

.c-info-card {
    border: 2px solid $primary-color;
    padding: 25px;
    font-size: 14px;

    li {
        margin-left: 15px;
    }
}