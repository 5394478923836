@mixin accordionBorder($top) {
    content: '';
    position: absolute;
    top: $top;
    left: 0;
    height: 5px;
    width: 100%;
    background-image: linear-gradient(to right, $white 50%, rgba(0,0,0,0) 0%);
    background-position: bottom left;
    background-size: 40px 10px;
    background-repeat: repeat-x;
    opacity: 0.4;
}

.c-accordion {
    position: relative;
    background-color: $primary-color;
    color: $white;
    border-radius: $border-radius-base;

    $this: &;

    &__body {
        position: relative;
        &:before {
            @include accordionBorder(0)
        }
        overflow: hidden;
        max-height: 0;
        opacity: 0;
        transition: opacity $transition-default;
        &.is-visible {
            max-height: 3000px; // just a random big number
            opacity: 1;
        }

        .is-disabled {
            opacity: 0.6;
            pointer-events: none;
        }
    }

    &__trigger {
        height: 40px;
        width: 40px;
        border: 4px solid $white;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $secondary-color;
        color: $white;
        position: absolute;
        bottom: 0;
        left: 25%;
        transform: translate(-50%, 50%);
        z-index: 1;
    }

    &.no-border {
        #{$this}__body:before {
            display: none;
        }
    }

    &.is-disabled {
        opacity: 0.6;
        pointer-events: none;
    }

}
