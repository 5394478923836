.import-download-media {
  #label-update-media {
    cursor: pointer;
    text-decoration: underline;
    align-self: flex-end;
    margin-left: 25px;
  }

  #label-upload-media {
    cursor: pointer;
  }

}
