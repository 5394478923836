.c-progress {
  position: relative;
  svg {
    display: block;
    margin: auto;
  }
  &__bkg {
    fill: transparent;
    stroke: $grey60;
  }
  &__progress {
    fill: transparent;
    stroke: $blue80;
    transition: stroke-dashoffset 0.35s;
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
  }
  &__indicator {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: $font-weight-bold;
    font-size: $font-size-s;
  }
}
