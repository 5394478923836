* {
    -webkit-font-smoothing: antialiased;
}

@mixin heading() {
    font-family: $font-family-heading;
    font-weight: normal;
    margin: 0;
    line-height: 1;
}

.c-h1 {
    @include heading();
    font-size: rem($font-size-xl);
    @media (min-width: $breakpoint-main) {
        font-size: rem($font-size-xxl);
    }

}

.c-h2 {
    @include heading();
    font-size: rem($font-size-l);
    @media (min-width: $breakpoint-main) {
        font-size: rem($font-size-xl);
    }
}

.c-h3 {
    @include heading();
    font-size: rem($font-size-m);
    @media (min-width: $breakpoint-main) {
        font-size: rem($font-size-l);
    }
}

.c-h4 {
    @include heading();
    font-size: rem($font-size-m);
}

.c-h5 {
    @include heading();
    font-size: rem($font-size-s);
}

.c-list-hz {
    display: flex;
    flex-direction: column;
    list-style: none;
    @media (min-width: $breakpoint-main) {
        flex-direction: row;
    }
    &__item {
        @media (min-width: $breakpoint-main) {
            flex: 1 0 0;
            position: relative;
        }

    }
    &__item + &__item{
        &:before {
            content: "";
            width: 100%;
            height: 2px;
            background-color: $primary-color;
            position: absolute;
            top: 0;
            left: 0;
        }
        @media (min-width: $breakpoint-main) {
            &:before {
                content: "";
                height: 100%;
                width: 1px;
                background-color: $primary-color;
                position: absolute;
                top: 0;
                left: 0;
            }
        }
    }
}