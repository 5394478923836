@import "styles/variables.scss";

select.audit-certification {
  color: $black;
  border-radius: 5px;
  border: 1px solid $primary-color;

  &.waiting-certif {
    background-color: $white;
    border-color: $black;
  }

  &.no-certif {
    background-color: $grey60;
    border-color: $grey60;
  }

  &.bronze-certif {
    color: $white;
    background-color: $bronze;
    border-color: $bronze;
  }

  &.silver-certif {
    color: $white;
    background-color: $silver;
    border-color: $silver;
  }

  &.gold-certif {
    background-color: $gold;
    border-color: $gold;
  }

  option {
    background-color: $white;
    color: $black;
  }
}
