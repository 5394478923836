$form-background: $white;
$form-color-primary: $text-color;
$form-color-secondary: $grey70;
$form-border-color: $form-color-primary;
$form-height: 35px;
$form-font-size: $font-size-xxs;

// Shared styles for forms
%form-appearance {
  border: 0;
  outline: 0;
  border-bottom: 1px solid $form-border-color;
  border-radius: 0;
  background-color: $form-background;
  color: $form-color-primary;
  font-size: $form-font-size;
  min-height: $form-height;
  text-align: left;

  padding: $space-s;

  @include placeholder($form-color-secondary);
  // @include focus();

  &.has-error {
    border: 1px solid $danger-color;
  }

  &[disabled] {
    opacity: 0.6;
    pointer-events: none;
  }

  .is-light & {
    border-color: $white;
    background-color: transparent;
    color: $white;
  }
}

.c-label {
  display: block;
  color: $primary-color;
  margin: 0;
  font-size: 1em;
  font-weight: $font-weight-bold;
  .is-light & {
    color: $white;
  }
}

.c-input {
  @extend %form-appearance;
  display: block;
  width: 100%;
  padding-left: 1rem;

  // &:disabled {
  //   border-bottom: none;
  // }

  & + button {
    position: absolute;
    right: 10px;
    top: 24px;
    display: flex;
    &.c-btn {
      padding: 0 $space-s;
      min-height: 26px;
      min-width: 0;
    }
    .is-light & {
      color: $white;
    }
  }
}

.c-textarea {
  @extend %form-appearance;
  display: block;
  width: 100%;
  min-height: 80px;
  resize: none;
}

// Fake input
.c-data {
  &__label {
    @extend .c-label;
  }
  &__value {
    @extend %form-appearance;
    display: flex;
    justify-content: space-between;
    flex: 1;
  }
}

// Checkbox
// -----------

.c-checkbox {
  position: relative;
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  appearance: none;
  // border: 1px solid $primary-color;
  outline: 0 !important;

  &:after {
    content: "";
    position: absolute;
    top: 50%;
    right: 50%;
    bottom: 50%;
    left: 50%;
    transition: all 0.1s;
  }

  &:before {
    content: "";
    display: block;
    height: 100%;
    width: 100%;
    border: 1px solid $primary-color;
    transition: all 0.1s;
    .is-light & {
      border: 1px solid $white;
    }
  }

  &:checked:before {
    // border-radius: 50%;
    background-color: $primary-color;
    .is-light & {
      border: 1px solid $white;
    }
  }
  &:checked:after {
    display: block;
    position: absolute;
    top: 2px;
    left: 7px;
    width: 6px;
    height: 12px;
    border: solid $white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    // .is-light & {
    //     border: solid $primary-color;
    // }
  }

  &:hover,
  & + p:hover {
    cursor: pointer;
  }

  // Specific
  &--cgu {
    width: 10px;
    height: 10px;
    margin-top: $space-xs + 2 !important;
    &:before {
      border-color: $grey90;
    }
    &:checked:before {
      background-color: $grey90;
    }
    &:checked:after {
      top: 2px;
      left: 4px;
      width: 3px;
      height: 6px;
      border: solid $white;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }

  &[disabled],
  &[disabled] + p {
    opacity: 0.4;
    pointer-events: none;
  }
}

// Radio
// -----------

.c-radio {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding-left: 30px;
  font-size: $form-font-size;

  &.input-hidden {
    border-color: $primary-color;
    color: $primary-color;
    padding-left: 45px;

    &:before,
    &:after {
      position: absolute;
      left: -10000px;
      width: 1px;
      height: 1px;
      overflow: hidden;
    }

    &.is-checked {
      background-color: $primary-color;
      color: $white;
    }
  }

  &:hover {
    cursor: pointer;
  }

  &:before,
  &:after {
    content: "";
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 50%;
    display: flex;
    align-items: center;
  }

  &:before {
    width: 20px;
    height: 20px;
    transform: translateY(-50%);
    border: 1px solid $primary-color;
    .is-light & {
      border: 1px solid $white;
    }
  }

  &.is-checked:before {
    border-color: $primary-color;
    .is-light & {
      border: 1px solid $white;
    }
  }

  &.is-checked:after {
    width: 10px;
    height: 10px;
    transform: translate(50%, -50%);
    border-radius: 50%;
    background-color: $primary-color;
    border-color: $primary-color;
    .is-light & {
      background-color: $white;
      border-color: $white;
    }
  }

  &.is-disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
}

// Group
// -----------

.c-form-group {
  position: relative;
  .c-label {
    line-height: 1;
    margin-bottom: -3px;
  }
  &--picto {
    .c-input-picto {
      position: absolute;
      bottom: 8px;
      left: 8px;
    }
  }
}

.c-input-button {
  position: relative;
  .c-input {
    padding-right: 70px;
  }
  .c-btn {
    font-weight: bold;
    min-width: 0;
    min-height: 0;
    padding: 0;
    border: 0;
    position: absolute;
    bottom: 7px;
    right: 0;
  }
}

// Select
// -----------

.c-select {
  @extend %form-appearance;

  &--is-disabled {
    border-bottom: none;
    opacity: 0.5;
    pointer-events: none;

    .c-select__indicators {
      display: none;
    }
  }

  & > &__control {
    border: 0;
    background-color: transparent;
    color: inherit;
    box-shadow: none;
    border-radius: 0;
    min-height: 0 !important;
  }

  &__single-value {
    color: currentColor !important;
    .is-light & {
      color: $white;
    }
  }

  &__value-container {
    padding: 0 !important;
    > div {
      margin: 0 !important;
      padding: 0 !important;
    }
    &:hover {
      cursor: pointer;
    }
  }

  & &__indicator {
    color: $black;
    padding: 0 !important;
    &-separator {
      display: none;
    }
    .is-light & {
      color: $white;
    }
  }

  & > &__menu {
    margin: 0;
    left: 0;
    box-shadow: none;
    border-radius: 0;
    background: $form-background;
    border: 1px solid $form-color-secondary;
    z-index: 99;
    .is-light & {
      background: $primary-color;
      border: 1px solid $white;
      color: $white;
    }
  }

  & &__menu-list {
    max-height: 200px;
  }

  & &__option {
    cursor: pointer;
    .is-light & {
      background-color: $primary-color;
    }
  }

  .is-inactive & {
    pointer-events: none;
  }
  &:hover {
    cursor: pointer;
  }
}

// Input file
// -----------

.c-input-file {
  @extend %form-appearance;
  display: flex;
  align-items: center;
  width: 100%;
  padding-left: 1rem;
  cursor: pointer;
}

// Datepicker
// -----------

.c-datepicker {
  @extend %form-appearance;
  width: 100%;
  cursor: pointer;
}

// react datepicker dependency
.c-form-group .react-datepicker {
  border: 1px solid $primary-color;
  &__header {
    background-color: $primary-color;
    .is-light & {
      border: 1px solid $white;
    }
  }
  &__triangle {
    border-bottom-color: $primary-color !important;
  }
  &__navigation--previous {
    border-right-color: $white;
  }
  &__navigation--next {
    border-left-color: $white;
  }
  &__navigation,
  &__current-month,
  &__day-name {
    color: $white;
  }
  &__day--keyboard-selected {
    background-color: $white;
    color: currentColor;
    border: 2px solid $black;
  }
  &__day--selected {
    background-color: $primary-color;
    color: $white;
    border: 0;
  }

  // Year dropdown
  &__year-read-view {
    display: flex;
    flex-direction: row-reverse;
    align-content: center;
    justify-content: center;
  }
  &__year-read-view--selected-year {
    color: $white;
  }
  &__year-read-view--down-arrow {
    margin: 0 0 0 6px;
    top: 5px;
    border-width: 6px;
    border-top-color: white;
  }

  &-wrapper {
    width: 100%;
  }
}

// Range
// -----------

.c-range {
  .rc-slider-track {
    background-color: $primary-color;
  }
  .rc-slider-handle {
    border-color: $primary-color;
  }
  .rc-slider-dot-active {
    border-color: $primary-color;
  }
  .rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
    border-color: $primary-color;
    box-shadow: 0 0 0 5px rgba($primary-color, 0.5);
  }
  .rc-slider-handle-click-focused:focus {
    border-color: $primary-color;
  }
  .rc-slider-handle:hover {
    border-color: $primary-color;
  }
  .rc-slider-handle:active {
    border-color: $primary-color;
    box-shadow: 0 0 5px $primary-color;
  }
  .rc-slider-mark {
    margin-top: $space-m;
  }
}
