@import "styles/variables.scss";

.table-container {
  border-collapse: collapse;
  display: block;
  overflow-x: auto;
  min-height: 400px;

  .flag-icon {
    margin-right: 0.1em;
  }

  td {
    padding: 0.5em;
  }

  th {
    background: $white;
    color: $grey80;
    padding: 0.5em;

   /*.filter-trigger {
      position: absolute;
      background-color: transparent;
      width: 100%;
    }*/

    .filter {
      background-color: white;
      text-align: left;
      position: absolute;
      padding: 10px;
      border-radius: 5px;
      border: 2px solid black;
      z-index: 3;
      min-width: 180px;
      top: 40px;
      input {
        margin-right: 5px;
      }
      &.show {
        display: block;
      }
      &.hide {
        display: none;
      }
    }
  }

  .flex-row {
    width: 12%;
    min-width: 169px;
    text-align: center;
  }

  tbody {
    tr {
      color: $blue80;
      font-size: $font-size-xxs;
      td {
        background-color: white;
      }
      &:nth-child(odd) {
        td {
          background-color: $grey30;
          transition: background-color $transition-default;
        }
      }
    }
  }
}

.fixedfirstcolumns {
  th:nth-child(1),
  td:nth-child(1),
  th:nth-child(2),
  td:nth-child(2) {
    position: sticky;
  }

  th:nth-child(1),
  td:nth-child(1) {
    left: 0;
    z-index: 2;
    //background-color: #f2f2f2;
  }

  th:nth-child(2),
  td:nth-child(2) {
    left: 169px; /* Adjust the value based on the width of the first column */
    z-index: 1;
    //background-color: #eaeaea;
  }

  th:nth-child(2) {
    z-index: 3;
  }
}
