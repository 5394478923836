@mixin focus() {
    &:focus {
        outline: none;
    }
    &:focus:not(:focus-visible) {
        outline: none;
    }
    &:focus-visible {
        outline: 3px auto $black;
        outline-offset: 5px;
    }
}
