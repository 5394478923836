@import "styles/variables.scss";

$side-panel-active-color: #93aab3;
$side-panel-active-background-color: $grey50;

.breadcrumb {
    padding: 20px 0;
    .text{
        vertical-align: middle;
        text-decoration: none;
        font-size: 22px;
        color: $primary-color;
        margin-right: 15px;
    }
    a {
        &.is-active {
            cursor: auto;
            color: $green90;
        }
        &:not(.is-active) {
            &:hover,
            &:focus {
                color: $side-panel-active-color;
                background-color:  $side-panel-active-background-color;
                border-color: $side-panel-active-color;
            }
        }
    }
}