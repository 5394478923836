// ----------
// Width
// ----------

@mixin generate-max-w-percent() {
    @for $i from 1 through 100 {
        @if $i % 10 == 0 {
            .u-max-w-#{$i}-percent { max-width: $i * 1%; }
        }
    }
}

@include generate-max-w-percent();

@mixin generate-w-percent() {
    @for $i from 1 through 100 {
        @if $i % 10 == 0 {
            .u-w-#{$i}-percent { width: $i * 1%; }
        }
    }
}

@include generate-w-percent();
