$aside-shadow-size: 2px;
$aside-gradient: 153.43deg, $white 0%, $grey50 100%;
$aside-color: $text-color;
$aside-active-color: $primary-color;
$aside-active-background-color: $grey50;

.c-aside {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 0 $aside-shadow-size 5px 0 rgba(0,0,0,0.05);
    background-color: $white;
    color: $aside-color;

    &__header {
        flex: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    &__nav {
        flex: 1;
    }

    &__footer {
        flex: 0;
    }

    &__item {
        display: flex;
        align-items: center;
        border-left: 5px solid transparent;
        color: currentColor;
        background-color: transparent;
        transition: color $transition-default,
                    border-color $transition-default
                    background-color $transition-default;

        svg { flex: 0 0 auto }
        a { flex: 1 }

        &.is-active,
        &:hover,
        &:focus {
            color: $aside-active-color;
            background-color:  $aside-active-background-color;
            border-color: $aside-active-color;
            cursor: pointer;
        }

        &.is-disabled {
            opacity: 0.7;
            cursor: not-allowed;
        }
    }
}
