@import "styles/variables.scss";

.workflow {
    position: relative;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 100px;

    &::before {
        content: '';
        border-top: 4px dotted $secondary-color;
        width: 100%;
        position: absolute;
        top: 77%;
        transform: translateY(-50%);
        z-index: -2;
    }

    .currentPosition {
        left: 50%;
        top: 0px;
        text-align: center;
        margin-bottom: 10px;
    }

    .position {
        left: 50%;
        top: 0px;
        text-align: center;
        height: 30px;
        margin-bottom: 10px;
    }

    .dot {
        position: relative;
        height: 30px;
        width: 30px;
        border: 4px solid $white;
        border-radius: 50%;
        background-color: $secondary-color;
        .title {
            position: absolute;
            transform: translateX(-50%);
            left: 50%;
            top: 30px;
            text-align: center;
        }
    }
}