@import "styles/variables.scss";

$side-panel-active-color: $grey30;
$side-panel-active-background-color: $blue80;
$side-panel-default-background-color: white;

.sidenav {
  height: 100%;
  width: $side-panel-width;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: $side-panel-default-background-color;
  overflow-x: hidden;
  padding-top: 20px;
  text-align: center;
  transition: background-color $transition-default;

  .c-mail {
    overflow-wrap: break-word;
    padding: 0 5px;
  }

  .menu-item-logo {
    padding-bottom: 70px;
  }

  .logo,
  .menu-item-logo {
    height: 70px;
    width: auto;
    margin-bottom: 30px;
    &.is-active,
    &:hover,
    &:focus {
      color: $side-panel-active-color;
      background-color: $side-panel-default-background-color;
      border-color: $side-panel-active-color;
    }
  }

  .icon {
    margin-right: 15px;
  }

  .menu-item {
    text-align: left;
    flex-direction: row;
    display: flex;
  }

  .sub-items {
    list-style: none;
    a.sub-item {
      font-size: 1em;
      font-style: italic;
      padding: 10px 0;
      color: $primary-color;
      transition: background-color $transition-default;
      &.is-active,
      &:focus {
        font-weight: 600;
        background-color: $grey50;
      }
      &:hover {
        background-color: $grey60;
      }
      span {
        padding: 5px;
      }
    }
  }

  a,
  button {
    padding: 20px;
    text-decoration: none;
    font-size: 20px;
    color: $primary-color;
    display: block;
    width: 100%;
    transition: background-color $transition-default;
    &.is-active,
    &:hover,
    &:focus {
      color: $side-panel-active-color;
      background-color: $side-panel-active-background-color;
      border-color: $side-panel-active-color;
    }
  }
}
