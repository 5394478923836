.c-item {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    color: $white;
    background-color: $primary-color;
    border-radius: $border-radius-base;

    &.is-disabled {
        opacity: 0.5;
        pointer-events: none;
    }
}

.c-item-catalogue {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    color: $primary-color;
    border: 3px solid $primary-color;

}