@import "styles/variables.scss";

.vertical-align {
    vertical-align: middle;
}

.c-information-data {
    position: relative;
    .c-tooltip-icon {
        position: absolute;
        top: -5px;
        right: 0;
    }

    .__react_component_tooltip {
        padding: 12px;
        width: 250px;
        background-color: $primary-color;
      }
}