@mixin helloInnerLabel($translateY) {
    width: 100%;
    height: 100%;
    transform: translateY($translateY);
    div {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        white-space: nowrap;
        transition: all $transition-default;
    }
}

@mixin setHelloInnerLabelState($this, $state) {
    #{$this}__label div {
        @if ($state == 'label') {
            transform: translateY(0%);
            opacity: 1;
        }
        @else {
            transform: translateY(-100%);
            opacity: 0;
        }
    }
    #{$this}__hover div {
        @if ($state == 'hover') {
            transform: translateY(0%);
            opacity: 1;
        }
        @else {
            transform: translateY(100%);
            opacity: 0;
        }
    }
    #{$this}__done div {
        @if ($state == 'done') {
            transform: translateY(0%);
            opacity: 1;
        }
        @else {
            transform: translateY(100%);
            opacity: 0;
        }
    }
}

.c-footer {
    width: 100%;
    font-size: $font-size-s;
    background-color: $white;
    color: $primary-color;
    border-top: 1px solid $primary-color;

    &__copy-cta {
        position: relative;
        display: inline-block;
        height: 26px;
        text-align: left;
        pointer-events: auto;
        overflow: hidden;

        @include focus();

        $this: &;

        &__label {
            @include helloInnerLabel(0%);
        }
        &__hover {
            @include helloInnerLabel(-100%);
        }
        &__done {
            @include helloInnerLabel(-200%);
        }

        // States
        &:hover,
        &:focus {
            @include setHelloInnerLabelState($this, 'hover');
        }

        &.is-copied {
            @include setHelloInnerLabelState($this, 'done');
        }

        &:not(:hover, :focus, .is-copied) {
            @include setHelloInnerLabelState($this, 'label');
        }
    }

}
