.c-drawer-wrapper {
    position: fixed;
    width: 100vw;
    height: 100vh;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    z-index: 10;

    overflow: hidden;

    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.c-drawer-overlay {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: $primary-color;
    top: 0;
    left: 0;
    z-index: 1;
    cursor: pointer;
    transition: opacity .6s cubic-bezier(.215,.61,.355,1);
}

.c-drawer {

    position: relative;
    display: flex;
    flex-direction: column;

    width: 500px;
    @media (min-width: $breakpoint-main) {
        max-width: 80vw;
    }
    height: 100vh;
    overflow: auto;

    background-color: $white;
    box-shadow: 0px 1px 10px rgba(32, 60, 94, 0.2);
    transition: transform .2s ease-out;

    z-index: 2;


    &__close {
        font-size: $font-size-m;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

}
