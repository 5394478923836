// Table grid
@mixin tableColumn($suffix) {
	@for $i from 1 through 24 {
		.c-table__cell--#{$i}#{$suffix} {
			width: calc(100% / 24 * (#{$i}));
		}
    }
}

@include tableColumn('');

@media (min-width: $breakpoint-main) {
	@include tableColumn(\@main);
}

// Table
.c-table {

    /* Local variables */
    $table-font-size: $font-size-xs;
    $table-border-width: 2px;
    $table-separator-width: 1px;
    $table-active-color:  $primary-color;

    // Default theme
    $table-color: $black;
    $table-border-color: $grey70;
    $table-separator-color: $table-border-color;

    $table-head-color: $white;
    $table-head-font-size: $font-size-m;
    $table-head-background-color: $primary-color;
    $table-odd-background-color: $grey30;

    position: relative;
    display: block;
    width: 100%;
    font-size: $table-font-size;
    color: $table-color;

    table {
        display: block;
    }

    // Elements

    &__row {
        position: relative;
        display: flex;
        align-items: center;
        &:nth-child(odd) {
            background-color: $table-odd-background-color;
            transition: background-color $transition-default;
        }

        &.is-inactive {
            opacity: 0.4;
        }
    }

    &__row + &__row  {
        border-top: $table-separator-width solid $table-separator-color;
        transition: border-color $transition-default;
    }

	&__cell {
		position: relative;
		font-size: 1em;
        display: block;
        overflow: hidden;
    }

    // Existing table components

    thead {
        display: block;
        width: 100%;
        background-color: $table-head-background-color;
        color: $table-head-color;
        border-bottom: $table-separator-width solid $table-separator-color;

        transition: background-color $transition-default,
                    color $transition-default,
                    border-color $transition-default;

        tr {
            background-color: transparent !important;
        }
    }

    tbody {
        display: block;
        width: 100%;
    }

    th {
        display: flex;
        align-items: center;
        text-transform: lowercase;
        font-variant: small-caps;
        font-size: $table-head-font-size;
        font-weight: bold;
        line-height: 1;
        outline: 0;
    }

    // Modifiers
    &--transparent {
        $table-cell-padding: 0;
        $table-border-color: transparent;
    }

    &--secondary {
        thead {
            background-color: $secondary-color;
        }
        tr {
            background-color: $white;
        }
    }

    $this: &;

    // Responsive table
    &--responsive {

        #{$this}__row {
            @media (max-width: #{ $breakpoint-main - 1 }) {
                display: block;
            }
        }

        #{$this}__cell {
            @media (max-width: #{ $breakpoint-main - 1 }) {
                // display: flex;
                // justify-content: space-between;
                width: 100%;
            }
        }

        #{$this}__duplicated-title {
            display: none;
            @media (max-width: #{ $breakpoint-main - 1 }) {
                display: block;
                font-weight: bold;
            }
        }
    }

    thead {
        @media (max-width: #{ $breakpoint-main - 1 }) {
            display: none;
        }
    }

}

// Specific
.c-table-providers {

    .c-pagination {
        display: flex;
        align-items: center;
        .c-btn {
            min-height: 0;
            min-width: 0;
            height: 35px;
        }
    }

    .c-select {
        background-color: $white;
        border: 1px solid $primary-color;
        border-radius: $border-radius-base;
        height: 35px;
        min-height: 0;
        padding: 0;
        min-width: 80px;
    }

    .c-input {
        @include focus();
        background-color: transparent;
        border-bottom: 1px solid $primary-color;
    }

    .c-dropdown {
        &__toggle {
            background-color: $white !important;
            color: $primary-color !important;
            font-family: $button-font-family;
            font-weight: $button-font-weight;
            font-size: $button-font-size;
            border-radius: $button-radius ;
            border: 2px solid $primary-color !important;
            padding: $space-s $space-m;
            &[disabled] {
                opacity: 0.2;
            }
        }
        &__menu {
            background-color: $white !important;
            border: 2px solid $primary-color !important;
            border-top: 0 !important;
            margin-top: -10px;
            margin-left: 0;
            min-width: 0;
            button {
                padding-left: 0;
                padding-right: 0;
                text-align: center;
            }
        }
    }
}

.c-table-data {
    
    .c-table {
        width: fit-content;
    }

    &-container {
        position: relative;
        overflow-x: scroll;
        min-height: 200px;
    }

    .c-pagination {
        display: flex;
        align-items: center;
        .c-btn {
            min-height: 0;
            min-width: 0;
            height: 35px;
        }
    }
}