.c-roadmap {
  overflow-x: auto;

  $numberheight: 100px;
  $dateheight: 85px;
  $lineHeight: 40px;
  $dottedSize: 40px;

  &__wrap {
    position: relative;
    display: flex;
    justify-content: space-between;
    min-width: 1100px;
  }

  &__step {
    position: relative;
    width: calc(100% / 6);
    min-width: 245px;

    &::before,
    &::after {
      content: "";
      position: absolute;
      border-top: 5px dotted $secondary-color;
      // Position of the dotted line behind each step
      top: calc(#{$numberheight} + #{$dateheight} + 28px);
      width: 50%;
      height: 5px;
    }

    &::after {
      right: 0;
    }

    &::before {
      left: 5px;
    }

    // First and last step
    &:first-child::before {
      content: none;
    }

    &:last-child::after {
      content: none;
    }

    // Step open - active class
    &.is-visible {
      .c-roadmap__index {
        color: $primary-color;
        font-size: $numberheight;
      }

      .c-h2__title {
        color: $primary-color;
        position: relative;

        span {
          display: block;
          text-align: left;
          font-size: $font-size-s;
          position: absolute;
          min-width: 130px;
          left: calc(50% + 10px);
          top: 50%;
          transform: translateY(-50%);
        }

        &::before {
          background: $white;
          border: 3px solid $primary-color;
          height: calc(#{$dottedSize} - 4px);
          width: calc(#{$dottedSize} - 4px);
        }

        &::after {
          content: "";
          width: 1px;
          height: 125px;
          background: $primary-color;
          position: absolute;
          left: 50%;
          top: 0;
        }
      }
    }

    // specific for steps 4 and 5 if charter is signed
    &.has-charter-signed {
      &:nth-child(even) {
        &::before,
        &::after {
          border-color: $primary-color;
        }
      }
      &:nth-child(odd) {
        &::before {
          border-color: $primary-color;
        }
      }
    }

    &.is-done {
      &::after {
        border-color: $primary-color;
      }

      .c-roadmap__index {
        color: $primary-color;
      }

      .c-h2__title {
        color: $primary-color;
        position: relative;

        &::before {
          background: $primary-color;
        }
      }
    }

    &.is-done + &.is-active,
    &.is-done + &.is-done {
      &::before {
        border-color: $primary-color;
      }
    }
  }

  &__head {
    position: relative;
  }

  &__date {
    position: absolute;
    top: 0;
    left: calc(50% - 15px);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: $dateheight;
    color: rgba($primary-color, 0.6);
    font-size: $font-size-xs;
  }

  &__index {
    font-size: 60px;
    font-family: $font-family-heading;
    font-weight: $font-weight-bold;
    color: $secondary-color;
    height: $numberheight;
    display: block;
    line-height: $numberheight;
    transition: all 0.3s ease;
  }

  // Title with counter
  &__title {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    cursor: pointer;

    .c-h2__title {
      color: $grey60;
      max-width: 70%;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      min-height: 160px;
      transition: all 0.3s ease;

      // Pin on progress bar
      &:before {
        content: "";
        height: $dottedSize;
        width: $dottedSize;
        border: 6px solid $white;
        border-radius: 50%;
        display: flex;
        background-color: $secondary-color;
        z-index: 1;
        margin-bottom: 10px;
      }
    }
  }

  &__description {
    opacity: 0;
    display: none;
    border: 1px solid $primary-color;
    background: $white;
    border-radius: 8px;
    font-size: $font-size-xs;
    position: relative;
    margin: 5px;
    z-index: 2;

    &::before {
      // Create a line from description to join step
      content: "";
      width: 1px;
      height: $lineHeight;
      background: $primary-color;
      position: absolute;
      left: 50%;
      top: -$lineHeight;
    }

    &.is-visible {
      opacity: 1;
      display: block;
    }

    a {
      font-size: $font-size-xs;
    }
  }

  // Status
  .is-disabled {
    opacity: 0.9;
    pointer-events: none;
  }
}
