.c-map {
    height: 300px;
    @media (min-width: $breakpoint-main) {
        min-height: 300px;
        height: 100%;
    }
}

.c-map-popup {
    // background-color: red;
}

.c-map-marker {
    border-radius: 50%;
    height: 20px;
    width: 20px;
    background-color: $grey90;
    position: relative;
    &--selected {
        background-color: $primary-color;
        &:after {
            content: '';
            display: block;
            position: absolute;
            top: 2px;
            left: 7px;
            width: 6px;
            height: 12px;
            border: solid $white;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
            transition: all .1s;
        }
    }
}

// Override leaflet
.leaflet-popup-content-wrapper {
    border-radius: 0;
}

.leaflet-container a.leaflet-popup-close-button {
    top: 6px;
    right: 6px;
}

.leaflet-popup-content {
    p {
        margin: 0;
    }
}
.leaflet-div-icon {
    border: 0 !important;
    background-color: transparent !important;
}
.c-map-grid {
    min-height: 400px;
    border: 1px solid $grey60;
    position: relative;
    z-index: 0;
}

.c-map-list {
    height: 100%;
    max-height: 500px;
    max-width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    &__item {
        .is-active h5 {
            color: $primary-color;
        }
    }
    &__item + &__item {
        border-top: 1px solid $grey70;
    }
}