.u-sticky-view {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    .c-main-content {
        flex-grow: 1;
        width: 100%;
    }
}

hr.c-dotted-hr {
    position: relative;
    display: block;
    border: 0;
    &:before {
        content:"";
        position: absolute;
        border-top: 5px dotted $secondary-color;
        bottom: 0;
        top: 0;
        width: 100%;
        height: 10px;
        left: 0;
        transform: translateX(-50%);
        @media (min-width: $breakpoint-main) {
            left: 50%;
        }
    }
}