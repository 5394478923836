.l-form-width {
    // margin-left: auto;
    // margin-right: auto;
    width: 100%;
    max-width: 470px;
    // @media (min-width: $breakpoint-main) {
    //     max-width: 400px;
    // }
}

.l-form-width-container {
    // margin-left: auto;
    // margin-right: auto;
    width: 100%;
    // max-width: 400px;
    @media (min-width: $breakpoint-main) {
        max-width: 500px;
    }
}

.l-fixed-layout {
    .c-main-content {
        @media (min-width: $breakpoint-main) {
            height: 100vh;
        }
    }
    .l-grid {
        flex: 1 0 0;
        overflow: hidden;
        .l-col-12 {
            overflow: auto;
            @media (min-width: $breakpoint-main) {
                height: 100%;
            }
        }
    }
    .c-footer {
        flex: 0 0 0;
        width: 100%;
    }
}