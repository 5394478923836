// Colors
.u-white {
  color: $white;
  &\@main {
    @media (min-width: $breakpoint-main) {
      color: $white;
    }
  }
}
.u-black {
  color: $black;
  &\@main {
    @media (min-width: $breakpoint-main) {
      color: $black;
    }
  }
}
.u-primary {
  color: $primary-color;
  &\@main {
    @media (min-width: $breakpoint-main) {
      color: $primary-color;
    }
  }
}
.u-secondary {
  color: $secondary-color;
  &\@main {
    @media (min-width: $breakpoint-main) {
      color: $secondary-color;
    }
  }
}
.u-grey30 {
  color: $grey30;
  &\@main {
    @media (min-width: $breakpoint-main) {
      color: $grey30;
    }
  }
}
.u-grey50 {
  color: $grey50;
  &\@main {
    @media (min-width: $breakpoint-main) {
      color: $grey50;
    }
  }
}
.u-grey70 {
  color: $grey70;
  &\@main {
    @media (min-width: $breakpoint-main) {
      color: $grey70;
    }
  }
}
.u-grey90 {
  color: $grey90;
  &\@main {
    @media (min-width: $breakpoint-main) {
      color: $grey90;
    }
  }
}
.u-green60 {
  color: $green60;
  &\@main {
    @media (min-width: $breakpoint-main) {
      color: $green60;
    }
  }
}
.u-green90 {
  color: $green90;
  &\@main {
    @media (min-width: $breakpoint-main) {
      color: $green90;
    }
  }
}
.u-blue {
  color: $blue80;
  &\@main {
    @media (min-width: $breakpoint-main) {
      color: $blue80;
    }
  }
}
.u-yellow {
  color: $yellow;
  &\@main {
    @media (min-width: $breakpoint-main) {
      color: $yellow;
    }
  }
}

.u-text-color {
  color: $text-color;
}

.u-success {
  color: $success-color;
}
.u-warning {
  color: $warning-color;
}
.u-danger {
  color: $danger-color;

  .is-light & {
    color: $white;
  }
}

// Background-color
.u-bg-primary {
  background-color: $primary-color !important;
  &\@main {
    @media (min-width: $breakpoint-main) {
      background-color: $primary-color !important;
    }
  }
}
.u-bg-primary-reduced {
  background-color: rgba($primary-color, 0.1) !important;
  &\@main {
    @media (min-width: $breakpoint-main) {
      background-color: rgba($primary-color, 0.1) !important;
    }
  }
}
.u-bg-secondary {
  background-color: $secondary-color !important;
  &\@main {
    @media (min-width: $breakpoint-main) {
      background-color: $secondary-color !important;
    }
  }
}
.u-bg-black {
  background-color: $black;
  &\@main {
    @media (min-width: $breakpoint-main) {
      background-color: $black !important;
    }
  }
}
.u-bg-white {
  background-color: $white;
  &\@main {
    @media (min-width: $breakpoint-main) {
      background-color: $white !important;
    }
  }
}
.u-bg-grey30 {
  background-color: $grey30;
  &\@main {
    @media (min-width: $breakpoint-main) {
      background-color: $grey30 !important;
    }
  }
}
.u-bg-grey50 {
  background-color: $grey50;
  &\@main {
    @media (min-width: $breakpoint-main) {
      background-color: $grey50 !important;
    }
  }
}
.u-bg-grey60 {
  background-color: $grey60;
  &\@main {
    @media (min-width: $breakpoint-main) {
      background-color: $grey60 !important;
    }
  }
}
.u-bg-grey70 {
  background-color: $grey70;
  &\@main {
    @media (min-width: $breakpoint-main) {
      background-color: $grey70 !important;
    }
  }
}
.u-bg-grey90 {
  background-color: $grey90;
  &\@main {
    @media (min-width: $breakpoint-main) {
      background-color: $grey90 !important;
    }
  }
}
.u-bg-blue {
  background-color: $blue80;
  &\@main {
    @media (min-width: $breakpoint-main) {
      background-color: $blue80 !important;
    }
  }
}
.u-bg-yellow {
  background-color: $yellow;
  &\@main {
    @media (min-width: $breakpoint-main) {
      background-color: $yellow !important;
    }
  }
}
.u-bg-transparent {
  background-color: transparent !important;
  &\@main {
    @media (min-width: $breakpoint-main) {
      background-color: transparent !important;
    }
  }
}
.u-bg-danger {
  background-color: $danger-color;
  &\@main {
    @media (min-width: $breakpoint-main) {
      background-color: $danger-color !important;
    }
  }
}
