@import 'styles/variables.scss';

.response {
    min-width: 100px;
    width: auto;
	text-align: center;
    color: $primary-color;
	background-color: $white;
	border-color: $primary-color;
	padding: $space-xs;
	border-radius: $border-radius-base;
	border: 1px solid;
	font-family: $font-family-base;
	font-size: $font-size-xs;
}

.checked {
	color: $white;
	background-color: $primary-color;
	border-color: $primary-color;
}