@import "styles/variables.scss";

.back-office-layout {
    background-color: #F8F8F8;

    .is-disabled-block {
        opacity: 0.5;
        pointer-events: none;
    }
}

.back-office-footer {
    margin-left: $side-panel-width;
    width: calc(100% - #{$side-panel-width}) !important;
}

.back-office-right-part {
    margin-left: calc(#{$side-panel-width} + 4%);
    width: calc(92% - #{$side-panel-width}) !important;
    min-height: 93vh;
}