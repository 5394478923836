@import "styles/variables.scss";

select.audit-status {
  border-radius: 5px;
  border: 1px solid $primary-color;

  &.asked {
    background-color: $white;
    color: $primary-color;
  }

  &.ordered {
    color: $white;
    background-color: $primary-color;
    border-color: $primary-color;
  }

  &.realized {
    background-color: $green60;
    border-color: $green60;
    color: $black;
  }

  &.canceled {
    color: $black;
    background-color: $grey70;
    border-color: $grey70;
  }

  &.reserved {
    color: $black;
    background-color: $yellow;
    border-color: $grey70;
  }

  &.paid {
    color: $black;
    background-color: $blue30;
    border-color: $blue30;
  }

  option {
    background-color: $white;
    color: $black;

    &:disabled {
      color: $grey70;
    }
  }
}

div.canceled {
  color: $black;
  background-color: $grey70;
  border: 1px solid $grey70;
}