.picture {
    object-fit: cover;
    height: 140px;
    width: 100%;
    margin-bottom: 5px;
}

.c-picture {
    display: flex;
    flex-direction: column;
    width: 23%;
    margin: 0 1%;

    img {
        object-fit: cover;
        height: 140px;
        width: 100%;
        margin-bottom: 10px;
    }
}