@import "styles/variables.scss";

.audit-planning-page {
    li {
        &:first-of-type{
            margin-top: 15px;
        }
        list-style-position: inside;
        margin-left: 15px;
    }
    .in-pratice-panel{
        background-color: $grey30;
        li {
            list-style-type: "-";
        }
    }
    .nb-audit {
        color: $grey90;
    }
}