.c-modal-wrapper {
    position: fixed;
    width: 100vw;
    height: 100vh;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    z-index: 10;

    overflow: hidden;

    display: flex;
    align-items: center;
    justify-content: center;
}

.c-modal-overlay {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: $primary-color;
    top: 0;
    left: 0;
    z-index: 1;
    cursor: pointer;
    transition: opacity .6s cubic-bezier(.215,.61,.355,1);
    
    &.no-cursor {
        cursor: auto;
    }
}

.c-modal {

    position: relative;
    display: flex;
    flex-direction: column;

    height: auto;
    width: 90%;
    max-width: 800px;
    max-height: 90%;
    overflow: auto;

    color: $primary-color;
    background-color: $white;
    box-shadow: 0px 1px 10px rgba(32, 60, 94, 0.2);
    border-radius: $border-radius-base;

    z-index: 2;

    &__close {
        position: absolute;
        top: 20px;
        right: 20px;
        font-size: $font-size-m;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

}