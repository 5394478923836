.c-tabs {
  &__nav {
    display: flex;
    align-items: center;
    border-bottom: 1px solid $primary-color;
  }
  &__nav-item {
    flex: 1 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: $font-size-s;
    min-height: 50px;
    position: relative;

    @include focus();

    &.is-active {
      color: $blue80;
      font-weight: $font-weight-bold;
      @include add-arrow("top", 10px, $blue80, 1px, $primary-color, 50%);
      @media (min-width: $breakpoint-main) {
        @include add-arrow("top", 10px, $white, 1px, $primary-color, 50%);
      }
      &:before,
      &:after {
        bottom: 0;
        right: 0;
        left: 50%;
        margin-bottom: -1px;
      }
    }

    &:hover {
      cursor: pointer;
    }
  }
}
