@import "styles/variables.scss";

.monitoring-tabs {
    position: relative;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 50px;


    &.is-nested {
        justify-content: flex-end;
        > div {
            margin: 0 20px;
        }
    }

    &.has-nested {
        margin-bottom: 5px;
    }

    .grow-one {
        flex-grow: 1;
    }

    &:not(.is-nested) {
        &::before {
            content: '';
            border-top: 1px solid $secondary-color;
            width: 100%;
            position: absolute;
            top: 85%;
            transform: translateY(-50%);
            z-index: -2;
        }

        .dot  {
            position: relative;
            height: 20px;
            width: 20px;
            border: 4px solid $white;
            border-radius: 50%;
            background-color: $primary-color;
        }
    }


    &.is-nested {
        .navlink.current-position {
            font-size: 1.3em;
        }
    }

    .navlink {
        &:not(.current-position) {
            margin: auto;
            font-size: 0.95em;
        }
        &.current-position {
            cursor: auto;
            margin-bottom: 10px;
            font-size: 1.5em;
        }
        &:hover:not(.current-position){
            color:$primary-color;
        }
    }

    .position {
        left: 50%;
        top: 0px;
        text-align: center;
    }
}