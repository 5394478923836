// ----------
// Typography
// ----------

.u-bold {
  font-weight: $font-weight-bold;
}
.u-semibold {
  font-weight: $font-weight-semibold;
}
.u-medium {
  font-weight: $font-weight-medium;
}
.u-regular {
  font-weight: $font-weight-regular;
}
.u-light {
  font-weight: $font-weight-light;
}

.u-small {
  font-size: rem($font-size-s);
}
.u-lowercase {
  text-transform: lowercase;
}
.u-uppercase {
  text-transform: uppercase;
}
.u-capitalize {
  text-transform: capitalize;
}
.u-text-transform-none {
  text-transform: none;
}
.u-text-decoration-none {
  text-decoration: none;
}
.u-text-decoration-line-through {
  text-decoration: line-through;
}
.u-italic {
  font-style: italic;
}
.u-underline {
  text-decoration: underline;
}

.u-font-base {
  font-family: $font-family-base;
}
.u-font-heading {
  font-family: $font-family-heading;
}

.u-fs-base {
  font-size: rem($font-size-base);
  &\@main {
    @media (min-width: $breakpoint-main) {
      font-size: rem($font-size-base);
    }
  }
}
.u-fs-xxs {
  font-size: rem($font-size-xxs);
  &\@main {
    @media (min-width: $breakpoint-main) {
      font-size: rem($font-size-xxs);
    }
  }
}
.u-fs-xs {
  font-size: rem($font-size-xs);
  &\@main {
    @media (min-width: $breakpoint-main) {
      font-size: rem($font-size-xs);
    }
  }
}
.u-fs-s {
  font-size: rem($font-size-s);
  &\@main {
    @media (min-width: $breakpoint-main) {
      font-size: rem($font-size-s);
    }
  }
}
.u-fs-m {
  font-size: rem($font-size-m);
  &\@main {
    @media (min-width: $breakpoint-main) {
      font-size: rem($font-size-m);
    }
  }
}
.u-fs-l {
  font-size: rem($font-size-l);
  &\@main {
    @media (min-width: $breakpoint-main) {
      font-size: rem($font-size-l);
    }
  }
}
.u-fs-xl {
  font-size: rem($font-size-xl);
  &\@main {
    @media (min-width: $breakpoint-main) {
      font-size: rem($font-size-xl);
    }
  }
}
.u-fs-xxl {
  font-size: rem($font-size-xxl);
  &\@main {
    @media (min-width: $breakpoint-main) {
      font-size: rem($font-size-xxl);
    }
  }
}

.u-lh-1 {
  line-height: 1;
}
