/**********************
****** Options *******
**********************/

$white: #fff;
$black: #111113;

$grey30: #f2f3f4;
$grey50: #eff2f9;
$grey60: #dde3ee;
$grey70: #bbbbbb;
$grey80: #9e9e9e;
$grey90: #58585a;

$blue30: #a3e5fd;
$blue80: #005a7c;
$yellow: #f2b825;

$green60: #c0dab0;
$green90: #66b786;

$bronze: #614e1a;
$silver: #868992;
$gold: #efd807;

$font-family-base: "Source Sans Pro", Arial, sans-serif;
$font-family-heading: "Asap Condensed", $font-family-base;

$line-height-base: 1.4;

$font-size-xxs: 14px;
$font-size-xs: 15px;
$font-size-s: 18px;
$font-size-m: 21px;
$font-size-l: 32px;
$font-size-xl: 40px;
$font-size-xxl: 46px;

$font-size-base: $font-size-s;

$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;

$space-default: 16px;
$space-xxs: 2px;
$space-xs: 4px;
$space-s: 8px;
$space-m: 16px;
$space-l: 28px;
$space-xl: 48px;
$space-xxl: 96px;

$container-sm: 540px;
$container-md: 720px;
$container-lg: 960px;
$container-xl: 1140px;
$container-xxl: 1430px;

$breakpoint-xs: 0;
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;
$breakpoint-xxl: 1500px;
$breakpoint-main: $breakpoint-lg;

$border-base: 1px solid black;
$border-radius-base: 6px;
$border-radius-l: 12px;
$border-radius-xl: 24px;

$transition-time-default: 0.25s;
$transition-easing-default: cubic-bezier(0.17, 0.67, 0.52, 0.97);
$transition-easing-pop: cubic-bezier(0.07, 0.74, 0.13, 1.18);
$transition-default: $transition-time-default $transition-easing-default;

/**********************
****** Objects *******
**********************/

$primary-color: $blue80;
$secondary-color: $yellow;
$text-color: $black;

$success-color: #76a973;
$warning-color: #ffce00;
$danger-color: #c53762;

$html-font-size-root: $font-size-base;
$html-font-size-root-mobile: $font-size-base;

$body-background: $white;
$body-line-height: $line-height-base;
$body-font-family: $font-family-base;
$body-color: $text-color;

$link-text-color: currentColor;
$link-text-decoration: none;
$link-hover-color: $secondary-color;
$link-hover-decoration: none;

$hr-margin-top: $space-m;
$hr-margin-bottom: $space-m;
$hr-size: 3px;
$hr-color: rgba($primary-color, 0.5);

$aside-width: 300px;
$header-height: 80px;
$side-panel-width: 220px;