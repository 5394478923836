.import-download-meeting-report {
    #label-update-report {
        cursor: pointer;
        text-decoration: underline;
        align-self: flex-end;
        margin-left: 25px;
    }

    #label-upload-report {
        cursor: pointer;
    }
    
    input[type=file] {
        display: none;
    }
}