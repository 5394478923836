@import "styles/variables.scss";

select {
  color: $white;
  border-radius: 5px;
  border: 1px solid $primary-color;

  &.asked {
    background-color: $white;
    color: $primary-color;
  }

  &.planned {
    background-color: $primary-color;
  }

  &.realized {
    background-color: $green60;
    border: 1px solid $green60;
    color: $black;
  }

  &.canceled {
    color: $black;
    background-color: $grey70;
    border: 1px solid $grey70;
  }

  &.paid {
    color: $black;
    background-color: $blue30;
    border: 1px solid $blue30;
  }
}
